import { useEffect, useContext } from "react";
import {
  TableRow,
  TableCell,
  tableCellClasses,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { CartContext } from "../CartContext";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontFamily: "Figtree, sans-serif",
  },
  [`&.${tableCellClasses.body}`]: {
    fontFamily: "Figtree, sans-serif",
    textTransform: "uppercase",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const CartItem = ({ id, name, price, image, quantity }) => {
  const { cartItems, setCartItems } = useContext(CartContext);
  const removeCartItem = () => {
    //Finds the element with the ID and remove the element from the array
    cartItems.splice(
      cartItems.find((el) => el.id == id),
      1
    );
    // Updates the cartItems array with the current elements
    setCartItems([...cartItems]);
  };

  return (
    <StyledTableRow
      key={id}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <StyledTableCell align="center">
        <CloseIcon
          sx={{
            "&:hover": {
              cursor: "pointer",
            },
          }}
          onClick={removeCartItem}
        />
      </StyledTableCell>
      <StyledTableCell align="center">
        <img src={image} style={{ width: "200px" }} />
      </StyledTableCell>
      <StyledTableCell align="center">{name}</StyledTableCell>
      <StyledTableCell align="center">{price}</StyledTableCell>
      <StyledTableCell align="center">
        <TextField
          id="filled-number"
          type="number"
          size="small"
          sx={{ width: 150 }}
          inputProps={{
            maxLength: 10,
            defaultValue: quantity,
            min: 1,
            max: 15,
            style: { textAlign: "center", paddingTop: 0 },
          }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            if (e.target.value > 15) {
              e.target.value = 15;
            }
          }}
          variant="filled"
        />
      </StyledTableCell>
      <StyledTableCell align="center">500</StyledTableCell>
    </StyledTableRow>
  );
};

export default CartItem;
