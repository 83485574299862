/** @jsxImportSource @emotion/react */
import Model from "./img/headerBG.jpg";
import Logo from "./img/logo.png";
import { css } from "@emotion/react";
import { Container } from "@mui/system";
import { Button, Typography, Box } from "@mui/material";
function App() {
  const header = css`
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url(${Model}) no-repeat center center fixed;
    background-size: cover;
    height: 100%;
    padding: 50px 0;
    color: #ffffff;
  `;

  return (
    //Main Wrapper

    <main css={header}>
      {/* Text Container */}
      <Container
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 100%;
          width: 100%;
        `}
        sx={{
          fontSize: {
            xs: "0.8em",
            sm: "0.8em",
            md: "0.8em",
            xl: "1rem",
          },
        }}
        maxWidth="xl"
      >
        {/* Page 1 Slogan */}
        <div
          css={css`
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            align-items: center;
            font-family: "Figtree", sans-serif;
            gap: 3rem;
            text-align: center;
          `}
        >
          {/* First Row */}
          <Box
            sx={{
              flexDirection: {
                xs: "column",
                sm: "row",
              },
              gap: {
                xs: "5rem",
                sm: "1rem",
              },
            }}
            css={css`
              display: flex;
              width: 80%;
              height: 100%;
              align-items: flex-start;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              gap: 0.5rem;
            `}
          >
            <h1
              css={css`
                letter-spacing: 2px;
                text-transform: uppercase;
              `}
            >
              Shop premium clothing with
            </h1>
            {/* Image Container */}

            <Typography
              sx={{
                fontFamily: "Figtree, sans-serif",
                fontSize: {
                  xs: "2rem",
                  sm: "3rem",
                },
              }}
            >
              SAIKO
            </Typography>
          </Box>
          {/* Second Row */}
          <p style={{ width: "80%", fontSize: "1.5em" }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
            ullamcorper erat ligula. Nullam et ornare diam, venenatis posuere
            tortor. Maecenas posuere volutpat sodales. Nam vitae egestas leo, at
            mattis nisi. Fusce tempor nunc at libero mollis, non scelerisque
            nibh fermentum. Suspendisse vitae fringilla tortor, in semper purus.
            Vestibulum vestibulum maximus diam ac elementum.
          </p>
          {/* Button Container */}
          <div
            css={css`
              width: 80%;
            `}
          >
            <Button
              variant="outlined"
              sx={{
                width: "50%",
                color: "white",
                fontFamily: "Figtree, sans-serif",
                borderColor: "white",
                top: 0,
                "&:hover": {
                  borderColor: "white",
                },
              }}
            >
              SHOP NOW
            </Button>
          </div>
        </div>
      </Container>
    </main>
  );
}

export default App;
