import { createClient } from "contentful";

export const getProducts = async () => {
  try {
    const client = createClient({
      // This is the space ID. A space is like a project folder in Contentful terms
      space: "n5ikxx5xrew0",
      // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
      accessToken: "YN0YYZ9VcuYW_bpJRFEsLyz8imT3nWn3ZxCpi3ihGrY",
    });
    let contentful = await client.getEntries({
      content_type: "saikoProducts",
      order: "fields.id",
    });

    return contentful;
  } catch (err) {
    return err;
  }
};
