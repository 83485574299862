/** @jsxImportSource @emotion/react */
import { Container } from "@mui/system";
import { useState, useEffect, useContext } from "react";
import { getProducts } from "../helper";
import ShopItem from "../component/ShopItem";
import { Grid } from "@mui/material";

const Shop = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getProducts().then((contentfulData) => setData(contentfulData.items));
  }, []);

  return (
    <Container
      sx={{
        marginTop: "5rem",
        width: "100%",
        fontFamily: "Figtree, sans-serif",
      }}
    >
      <Grid
        container
        spacing={4}
        direction={{ xs: "column", sm: "column", md: "row" }}
        rowSpacing={{ xs: 15, md: 10 }}
      >
        {data.map((el) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={5}
            lg={4}
            sx={{ margin: "0 auto" }}
            key={el.fields.id}
          >
            <ShopItem
              id={el.fields.id}
              title={el.fields.title}
              price={el.fields.price}
              image={el.fields.image.fields.file.url}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Shop;
