import { useState } from "react";
import { CartContext } from "./CartContext";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Nav from "./component/Nav";
import App from "./App.js";
import Shop from "./pages/Shop";
import Cart from "./pages/Cart";
const RouteSwitch = () => {
  const [cartItems, setCartItems] = useState([]);
  return (
    <BrowserRouter>
      <Nav />
      <CartContext.Provider value={{ cartItems, setCartItems }}>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/cart" element={<Cart />} />
        </Routes>
      </CartContext.Provider>
    </BrowserRouter>
  );
};

export default RouteSwitch;
