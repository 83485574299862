import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { Container } from "@mui/system";
import CartItem from "../component/CartItem";
import { React, useContext } from "react";
import { CartContext } from "../CartContext";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontFamily: "Figtree, sans-serif",
  },
  [`&.${tableCellClasses.body}`]: {
    fontFamily: "Figtree, sans-serif",
  },
}));

const Cart = () => {
  const { cartItems, setCartItems } = useContext(CartContext);
  return (
    <Container
      sx={{
        marginTop: "5rem",
        width: "100%",
      }}
    >
      <TableContainer component={Paper} sx={{ width: "100%", height: "100%" }}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell align="center">Product Name</StyledTableCell>
              <StyledTableCell align="center">Price</StyledTableCell>
              <StyledTableCell align="center">Quantity</StyledTableCell>
              <StyledTableCell align="center">Sub Total</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cartItems &&
              cartItems.map((el) => (
                <CartItem
                  id={el.id}
                  name={el.title}
                  price={el.price}
                  image={el.image}
                  quantity={el.quantity}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default Cart;
