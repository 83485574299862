/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  AppBar,
  Toolbar,
  Stack,
  Button,
  IconButton,
  Drawer,
} from "@mui/material";
import StorefrontRoundedIcon from "@mui/icons-material/StorefrontRounded";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonIcon from "@mui/icons-material/Person";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Logo from "../img/logo.png";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Nav = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const flexContainer = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 80%;
    margin: 0 auto;
  `;

  const navIcon = css`
    width: 100%;
    padding: 1rem;
    color: black;
  `;

  const logoElement = css`
    justify-content: flex-start;
    width: 15%;
    cursor: pointer;
  `;

  function toggleDrawer() {
    setOpen(!open);
  }

  function handleClick(route) {
    navigate(route);
  }
  // Closes the Drawer when min width view port reaches 600px
  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 600px)");
    const handleMediaQueryChange = () => {
      if (mediaQuery.matches) {
        setOpen(false);
      }
    };

    mediaQuery.addEventListener("change", handleMediaQueryChange);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  function ButtonList() {
    return (
      <>
        <Button
          color="primary"
          css={navIcon}
          onClick={() => handleClick("/shop")}
        >
          <StorefrontRoundedIcon style={{ color: "black" }} />
        </Button>
        <Button
          color="primary"
          css={navIcon}
          onClick={() => handleClick("/cart")}
        >
          <ShoppingCartIcon style={{ color: "black" }} />
        </Button>
        <Button color="primary" css={navIcon}>
          <PersonIcon style={{ color: "black" }} />
        </Button>
      </>
    );
  }
  return (
    <AppBar position="static" sx={{ bgcolor: "white" }} spacing={5}>
      <Toolbar css={flexContainer}>
        {/* 1 -- Logo*/}
        <div size="small" css={logoElement} onClick={() => handleClick("/")}>
          <img src={Logo} width={"100%"} />
        </div>
        {/* 1.2 -- Mobile Components */}
        <IconButton
          //Displays the menu Button on certain width points
          sx={{
            marginLeft: "auto",
            display: {
              xs: "inherit",
              sm: "none",
            },
            color: "black",
          }}
          onClick={toggleDrawer}
        >
          <MenuIcon style={{ color: "black" }} />
        </IconButton>
        <Drawer
          variant="persistent"
          //Customizes Drawer Themes
          PaperProps={{ sx: { width: "100%" } }}
          anchor="left"
          open={open}
          onClose={toggleDrawer}
        >
          {/* Close Button */}
          <Button css={navIcon} onClick={toggleDrawer}>
            <CloseIcon />
          </Button>
          <ButtonList
            css={css`
              display: flex;
              width: 100%;
            `}
          />
        </Drawer>
        {/* 2  - Cart , Shop and User Buttons*/}
        <Stack
          direction="row"
          spacing={1}
          sx={{
            marginLeft: "auto",
            display: {
              xs: "none",
              sm: "inherit",
            },
          }}
        >
          <ButtonList />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default Nav;
