import { Card } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Button, CardActions } from "@mui/material";
import { useContext } from "react";
import { CartContext } from "../CartContext";
const ShopItem = ({ id, title, price, image }) => {
  const { cartItems, setCartItems } = useContext(CartContext);
  const addToCart = () => {
    console.log(cartItems);
    // Guard Clause
    if (cartItems.find((item) => item.id === id)) {
      return;
    }

    setCartItems((prev) =>
      prev.concat({ id, price, title, image, quantity: 1 })
    );
  };
  return (
    <Card sx={{ maxWidth: 450 }} variant="elevation" elevation={5}>
      <CardMedia title={title} component="img" image={image}></CardMedia>
      <CardContent sx={{ textTransform: "uppercase", textAlign: "center" }}>
        <h3>{title}</h3>
        <p>{price} PHP</p>
      </CardContent>
      <CardActions>
        <Button
          onClick={addToCart}
          size="small"
          color="primary"
          variant="outlined"
          sx={{ fontFamily: "Figtree, sans-serif", margin: "0 auto" }}
        >
          Add to cart
        </Button>
      </CardActions>
    </Card>
  );
};

export default ShopItem;
